<template>
    <div>
        <img alt="Vue logo" src="../assets/images/smng-logo.png" style="width: 300px; height: 300px;">
    </div>
    <div class="container">
        <!-- 버튼 영역 -->
        <div class="row my-2">
            <div class="col text-left"> <!-- 버튼 위치를 왼쪽으로 조정 -->
                <p class="h3 mb">서미누기의 POE 한국어 아이템 영어 번역기 v1.1</p>
                <!-- <p class="h5"><span class="text-warning">https 관련 설정이 마무리되면 사용하기가 더 쉬워질 수 있습니다. (현재 작업 중).</span></p> -->
                <p class="h5 mb-4"><span class="text-warning">번역되지 않은 텍스트는 라이브 방송 또는 디스코드 번역제보 채널에 이야기 부탁드려요!</span></p>
                <p class="h5 text-start"><span class="text-primary">3.25 칼구르 리그 반영 완료. 문제가 보일 경우, 제보 부탁드려요</span></p>
                <p class="h5 mb-4 text-start"><span class="text-primary"></span></p>
                <p class="h5 text-start"><span class="text-warning">사용 방법</span></p>
                <p class="text-start">순서 1. 인-게임에서 영어로 번역하고자 하는 <span class="text-info fw-bolder">아이템에 마우스를 올린 뒤 Ctrl+C를
                        눌러 아이템 정보를 복사한다.</span></p>
                <!-- <p class="text-start">순서 2. 번역할 텍스트에 Ctrl+V를 눌러 붙여넣기 하고, <button type="button" class="btn btn-xs btn-primary" disabled>번역</button> 버튼을 누른다.</p>
                    <p class="text-start">순서 3. 번역된 텍스트에 텍스트가 제대로 번역되었다면 <span class="text-success fw-bolder">텍스트 입력창에 마우스로 클릭 하고 Ctrl + A, Ctrl + V로 단축키로 텍스트를 복사한다.</span></p> -->
                <p class="text-start">순서 2. <button type="button" class="btn btn-xs btn-success" disabled>번역하기</button>
                    버튼을 누른다.</p>
                <!-- <p class="text-start">순서 3. <button type="button" class="btn btn-xs btn-success" disabled>번역하기</button> 버튼을 누른다.</p> -->
                <p class="text-start">순서 3. 영어로 번역된 아이템 정보를 <span class="text-danger fw-bolder">Items Tab → Create
                        Custom → Ctrl + V → Create</span> 추가해서 시뮬레이션한다.</p>

                <!-- 번역 버튼 -->
                <!-- <button @click="readyTranslateText" class="btn btn-danger mx-2">번역 준비</button>
                            <button @click="translateText" class="btn btn-primary mx-2">번역</button> -->
                <!-- 복사하기 버튼 -->
                <!-- <button @click="copyToClipboard" class="btn btn-success mx-2">복사</button> -->
                <button @click="executeTranslationFlow" class="btn btn-success mx-2">번역하기</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <CoupangPartners />
            </div>
        </div>
        <div class="row">
            <!-- 좌측 텍스트 영역: 입력 -->
            <div class="col-md-6">
                <div class="form-group">
                    <label for="inputTextArea" class="lead">번역할 텍스트</label>
                    <textarea v-model="inputText" class="form-control" id="inputTextArea" rows="30"
                        v-bind:readonly="false"></textarea>
                </div>
            </div>
            <!-- 우측 텍스트 영역: 출력 -->
            <div class="col-md-6">
                <div class="form-group">
                    <label for="outputTextArea" class="lead">번역된 텍스트</label>
                    <textarea v-model="outputText" class="form-control" id="outputTextArea" rows="30"></textarea>
                </div>
            </div>
        </div>
    </div>
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div class="col-md-12">
            <p class="text-center">seominugi.com is not affiliated with or endorsed by Grinding Gear Games.</p>
        </div>
    </footer>
</template>

<script>
// 두 개의 JSON 파일을 임포트합니다.
import smngBasic from '@/assets/korean-poe-smng-data-basic.json';
import smngCustom from '@/assets/korean-poe-smng-data-custom.json';
import items from '@/assets/korean-poe-trade-data-items.json';
import stats from '@/assets/korean-poe-trade-data-stats.json';
import itemClass from '@/assets/korean-poe-smng-data-item-class.json';
import unique from '@/assets/translation-unique.json';
import itemSuffix from '@/assets/smng-poe-item-suffix.js';

import CoupangPartners from './CoupangPartners.vue';

export default {
    name: 'TranslateText',
    components: {
        CoupangPartners
    },
    mounted() {
        document.body.style.backgroundColor = '#1E2952';

        const pTags = document.querySelectorAll('p');
        const labelTags = document.querySelectorAll('label');

        pTags.forEach(tag => {
            tag.style.setProperty('color', '#FCE0CD', 'important');
        });

        labelTags.forEach(tag => {
            tag.style.setProperty('color', '#FCE0CD', 'important');
        });
    },
    data() {
        return {
            inputText: '',
            outputText: '',
            translations: {},
            itemClass: {},
            itemSuffix: []
        };
    },
    created() {
        // 두 개의 JSON 데이터를 하나로 병합합니다.
        this.translations = { ...smngBasic, ...smngCustom, ...items, ...stats, ...unique };
        this.itemClass = { ...itemClass };
        this.itemSuffix = [...itemSuffix];
    },
    methods: {
        async readyTranslateText() {
            try {
                const text = await navigator.clipboard.readText();
                const processedText = text.replace(/\r/g, "");
                const lines = processedText.split('\n');

                if (lines.length >= 1) {
                    const firstLine = lines[0].trim();

                    // 정규식을 사용하여 첫 번째 줄이 한국어인지 영어인지 판단
                    const koreanMatches = firstLine.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g);
                    const englishMatches = firstLine.match(/[a-zA-Z]/g);

                    const koreanCount = koreanMatches ? koreanMatches.length : 0;
                    const englishCount = englishMatches ? englishMatches.length : 0;

                    // console.log("koreanCount의 값은?" + koreanCount);
                    // console.log("englishCount의 값은?" + englishCount);
                    this.inputText = processedText;

                    if (koreanCount > englishCount) {
                        return 'ko';
                    } else if (englishCount > koreanCount) {
                        return 'en';
                    } else {
                        return 'unknown'; // 알 수 없는 언어
                    }
                }
            } catch (err) {
                console.error('클립보드에서 읽기 실패:', err);
                return 'error'; // 예외 발생 시 문자열 반환
            }
        },
        async translateKoreanText() {
            try {
                console.log("번역 시작");
                const lines = this.inputText.split('\n'); // 입력 텍스트를 줄로 나눕니다.

                //먼저 마지막 줄이 비어있는 경우 삭제
                if (lines.length > 0 && lines[lines.length - 1] === '') {
                    lines.pop(); // 배열의 마지막 요소를 삭제
                }


                // pob에서 작동되지 않는 속성과,
                // 게임 내 설명글이 불필요하게 복사되는 문제 때문에 관련 글 삭제

                let filteredLines = lines.filter((line) => {
                    const redundantLinePattern = ["사용 시 충전", "현재 충전량:", "초 지속", "마시려면 우클릭", "패시브 스킬 트리에서 포인트를"];
                    for (const pattern of redundantLinePattern) {
                        if (line.startsWith(pattern)) {
                            return false;
                        }
                        else if (line.includes(pattern)) {
                            return false;
                        }
                    }
                    return true;
                });

                const translatedLines = filteredLines.map((line, index, array) => {

                    /*
                    마법 아이템은 아이템 베이스에 접두어와 접미어가 조합되어 아이템 이름이 만들어짐.
                    접두어와 접미어를 조합하여 접두어 + 아이템 이름 + 접미어로 이름이 만들어짐
 
                    이 경우, pob에 적용하기 위한 아이템 이름으로서 작동하지 않는 문제가 발생하여
                    마법 아이템 일 경우, 접두어와 대쉬를 포함하여 (-) 뒤에 붙는 접미어 삭제 처리
                    (ex.의원의 수은 플라스크 - 전복)
                    */
                    if (index === 2) {
                        if (array[1].includes("마법")) { // index 1의 텍스트에 "마법"이 포함되어 있는지 확인
                            // console.log("마법 텍스트가 포함된 라인을 찾았습니다. ", line);
                            let replaceLineText = line.replace(/^.*?\s+/, "").replace(/\s+-\s+.*$/, "");
                            return this.translateTextPart(replaceLineText)
                        }
                    }

                    // 아이템 이름을 영어로 바꾸기 위해 희귀 아이템인지 체크
                    let isPoeItem = false;
                    let isRareItem = false;
                    if (array[0].startsWith("아이템 종류:")) {
                        // console.log("아이템 종류로 텍스트 시작 - ", line);
                        isPoeItem = true;
                    }
                    if (array[1] === "아이템 희귀도: 희귀") {
                        // console.log("아이템 희귀도는 희귀 - ", line);
                        isRareItem = true;
                    }

                    const parts = array[0].split(/:\s*/);
                    const itemType = parts[1].trim();
                    if (array[1] === "아이템 희귀도: 희귀" && index === 5) {
                        if (line === itemType) {
                            return this.translateItemClass(line);
                        }
                    } else if (array[1] === "아이템 희귀도: 마법" && index === 4) {
                        if (line === itemType) {
                            return this.translateItemClass(line);
                        }
                    }

                    // console.log("isPoeItemType:", isPoeItem, "isItemRarityRare:", isRareItem);

                    if (index === array.length - 1 && line === "타락") {
                        return "Corrupted"; //"타락"은 동음이의어여서 이렇게 처리.
                    } else if (line === "심연") {
                        return "Abyss";
                    } else if (line === "스킬 사용 시 장착된 주문 발동, 재사용 대기시간 8초 (crafted)") {
                        /*
                        스킬 사용 시 장착된 주문 발동 번역이
                        poe 데이터에는
                        "Trigger a Socketed Spell on Using a Skill, with a # second Cooldown"으로 되어 있지만,
                        
                        in-Game과 pob 에서는
                        "Trigger a Socketed Spell when you Use a Skill, with a 8 second Cooldown"으로 되어 있음.
                        번역의 기준은 pob이므로 pob 번역 문구로 조정.
                        */
                        return "Trigger a Socketed Spell when you Use a Skill, with a 8 second Cooldown (crafted)";
                    } else if (index === 2 && isPoeItem && isRareItem) {
                        /*
                        일반적으로 3번째 줄에 아이템 이름이 있음.
                        희귀 아이템일 경우에 영어로 바꿔줘야함.
                        console.log("이름을 바꿔주세요. - ", line);
                        `itemSuffix` 배열에서 무작위로 단어 선택
                        */
                        const randomIndex1 = Math.floor(Math.random() * itemSuffix.length);
                        const combinedText = `seoMinugi ${itemSuffix[randomIndex1]}`;

                        // console.log("변경된 아이템 이름 - ", combinedText);
                        return combinedText;
                    } else {
                        // 숫자 값을 저장할 배열
                        let numbers = [];

                        if (line.includes(':') && !line.includes('슬롯:')) {

                            const parts = line.split(/:\s*/);

                            let skipNumberReplacement = parts[0].includes("추가된 소형 패시브 스킬 효과");

                            // 괄호 안의 영어를 임시로 제거하고, 위치 정보를 []로 기억합니다.
                            const englishInsideParentheses = [];
                            parts.forEach((part, index) => {
                                // 괄호 안의 영어 텍스트와 일치하는 부분을 찾습니다.
                                const matches = part.matchAll(/\s*\(((?!augmented)[A-Za-z\s]+)\)/g);
                                for (const match of matches) {
                                    // 각 일치하는 부분에 대해 위치 정보와 함께 englishInsideParentheses 배열에 저장합니다.
                                    englishInsideParentheses.push({
                                        text: match[1], // 괄호 안의 영어 텍스트
                                        position: index, // 해당 텍스트가 있는 parts 배열의 인덱스
                                        placeholder: `[${match[1]}]` // 위치 정보를 포함한 플레이스홀더
                                    });
                                    // 원본 텍스트에서 괄호 안의 영어 텍스트를 플레이스홀더로 대체합니다.
                                    parts[index] = part.replace(match[0], `[${match[1]}]`);
                                }
                            });

                            const processedParts = parts.map((part, index) => {
                                // 첫 번째 부분이 조건에 맞고, 현재 처리 중인 부분이 두 번째 부분이라면 숫자 치환을 수행하지 않음
                                if (skipNumberReplacement && index === 1) {
                                    return part; // 숫자 치환 없이 그대로 반환(숫자 그대로 번역을 시도해야 한다.)
                                }
                                return part;
                            });

                            let translatedParts = processedParts.map(part => {
                                if ((/(\d+(\.\d+)?)미터/g).test(part)) {
                                    return part.replace(/(\d+(\.\d+)?)미터/g, '$1 metres');
                                } else if (/\[enchant\]$/.test(part) || /\(augmented\)$/.test(part)) {
                                    /*
                                    [enchant] 또는 (augmented) 텍스트가 문자열의 마지막에 있는지 확인
                                    스킬 군 주얼에서 번역해야 할 텍스트 뒤에 enchant 또는 augmented 텍스트가 함께 있어 번역이 안됨
                                    */
                                    let cleanedPart = part.replace(/\[enchant\]$|\(augmented\)$/, '');
                                    let translatedPart = this.translateTextPart(cleanedPart.trim());

                                    if (/\[enchant\]$/.test(part)) {
                                        return translatedPart + ' [enchant]';
                                    } else if (/\(augmented\)$/.test(part)) {
                                        return translatedPart + ' (augmented)';
                                    }
                                } else {
                                    return this.translateTextPart(part);
                                }
                            });

                            console.log("번역된 부분:", translatedParts);

                            englishInsideParentheses.forEach((data) => {
                                let partToUpdate = translatedParts[data.position];
                                // []를 ()로 변경하고, 필요한 경우 띄어쓰기 조정
                                translatedParts[data.position] = partToUpdate.replace(`[${data.text}]`, ` (${data.text})`);
                            });

                            // 번역된 부분을 다시 콜론과 함께 조합
                            let combinedWithRestoredEnglish = translatedParts.reduce((acc, part, index) => {
                                return acc + (index < translatedParts.length - 1 ? part + ': ' : part);
                            }, '');

                            console.log("복원된 번역 텍스트:", combinedWithRestoredEnglish);

                            return combinedWithRestoredEnglish;
                        } else {
                            let placeholders = [];
                            let placeholderIndex = 0;
                            // 괄호 안의 영어 데이터를 포함하는 플레이스홀더 저장
                            let lineWithIndexedPlaceholders = line.replace(/\((.*?)\)/g, (match, p1) => {
                                let placeholder = `[PLACEHOLDER${placeholderIndex}]`;
                                placeholders.push({ placeholder, text: p1 });
                                placeholderIndex++;
                                return placeholder;
                            });

                            // 플레이스홀더를 제거하고 번역 진행
                            let lineWithoutEnglish = lineWithIndexedPlaceholders.replace(/\s*\[PLACEHOLDER\d+\]/g, '');
                            // 여기서 번역 프로세스를 적용하세요. 예: translatedLine = translate(lineWithoutEnglish);

                            // "할당, 결합된"으로 시작하는 텍스트 분리 로직 추가
                            const assignmentTextPattern = ["할당", "결합된", "상급"]; // 특정 단어로 시작하는 패턴 정의
                            let assignmentText = "";

                            if (lineWithoutEnglish !== "결합된 아이템") {
                                console.log("assignmentTextPattern 텍스트는?", lineWithoutEnglish)
                                for (const pattern of assignmentTextPattern) {
                                    if (lineWithoutEnglish.startsWith(pattern)) {
                                        assignmentText = pattern;
                                        console.log("assignmentText 텍스트는?", assignmentText)
                                        lineWithoutEnglish = lineWithoutEnglish.replace(pattern, '').trim(); // 해당 패턴 제거
                                        break; // 첫 번째로 매칭되는 패턴을 찾으면 반복 종료
                                    }
                                }
                            }

                            // console.log("괄호 속 데이터    -", placeholders)
                            // console.log("번역할 데이터    -", lineWithoutEnglish)

                            // 번역 제외 속성
                            const skipNumberReplacementText = ["패시브 스킬 1개 추가", "이렇게 발동된 주문의 비용", "이 플라스크가 생성하는 신성화 지대의 반경이 3배로 증가", "제작 속성 부여 최대 3개 보유 가능"];

                            const skipModifierTextPatterns = ["소환수가 1초마다 ", "1초마다 ", "가장 높은 능력치 2개의 수치가 동일하면 ", "인내 충전 하나당 1초마다 ", "4초 동안 적 낙담 유발", "추가되는 소형 패시브 스킬이 1초마다 생명력의", "이동 중일 때 1초마다 생명력의", "최근 4초 이내", "주변의 적의 카오스 저항 0", "퀄리티 2%당", "퀄리티 4%당", "사용 1회당", "적에게 적용된 냉각 효과 1%당 냉기 피해의"];

                            // 숫자를 텍스트로 간주해야 하는 문장 배열(예외 번역)
                            const exceptionSentences = ["2배의 피해를 줌", "3배의 효과", "4배의 속도", "20레벨 그림자 형상 발동", "4초에 걸쳐 상실됨", "10당", "100당", "당 받는 피해 1% 감폭", "최대 생명력의 1/10"];

                            // const skipImplictText = ["받는 카오스 피해"];
                            const skipImplictTextPatterns = ["홈 1개"];

                            let foundSpecialTexts = []; // 발견된 특정 텍스트를 저장할 배열

                            // 특정 텍스트 분리 로직
                            skipModifierTextPatterns.forEach(pattern => {
                                if (lineWithoutEnglish.startsWith(pattern)) {
                                    lineWithoutEnglish = lineWithoutEnglish.replace(pattern, ''); // 특정 텍스트 제거
                                    foundSpecialTexts.push(pattern); // 발견된 특정 텍스트 저장
                                    // console.log(foundSpecialTexts)
                                }
                            });

                            // 특정 숫자는 텍스트로 간주해야 함
                            if (!lineWithoutEnglish.startsWith("주얼 슬롯 패시브 스킬") &&
                                skipNumberReplacementText.some(text => lineWithoutEnglish.includes(text))) {

                                // console.log("텍스트로 간주해야하는 숫자가 포함되어 숫자 대체를 건너뜁니다.");
                            } else if (placeholders.length === 1 && placeholders[0] && placeholders[0].text === "implicit" &&
                                skipImplictTextPatterns.some(text => lineWithoutEnglish === text)) {
                                // console.log("텍스트로 간주해야하는 숫자가 포함되어 숫자 대체를 건너뜁니다.");
                            } else {
                                // 예외 문장이 포함된 경우 해당 부분을 제외한 숫자를 #으로 대체
                                let modifiedLine = lineWithoutEnglish;
                                let exceptionText = '';

                                // 예외 문장이 포함된 경우, 예외 문장을 __EXCEPTION__으로 대체하고, 해당 문장을 저장
                                exceptionSentences.forEach(exception => {
                                    if (modifiedLine.includes(exception)) {
                                        exceptionText = exception;  // 예외 문장을 저장
                                        modifiedLine = modifiedLine.replace(exception, `__EXCEPTION__`);
                                    }
                                });

                                // 숫자를 찾아서 배열에 저장하고 #으로 대체
                                const matches = modifiedLine.match(/\d+(\.\d+)?/g);
                                if (matches) {
                                    matches.forEach((match) => {
                                        numbers.push(match); // 숫자를 배열에 저장
                                    });
                                    modifiedLine = modifiedLine.replace(/\d+(\.\d+)?/g, '#'); // 숫자 자리를 #으로 대체
                                }

                                // 예외 문장을 복원
                                if (exceptionText) {
                                    modifiedLine = modifiedLine.replace(`__EXCEPTION__`, exceptionText);
                                }

                                lineWithoutEnglish = modifiedLine;

                                // 특정 텍스트 복원 로직
                                foundSpecialTexts.forEach(pattern => {
                                    lineWithoutEnglish = pattern + lineWithoutEnglish; // 번역된 문장 앞에 특정 텍스트 추가
                                });
                            }


                            // console.log("번역 직전 numbers   -", numbers)
                            console.log("번역 직전 lineWithoutEnglish   -", lineWithoutEnglish)
                            let translatedLine = this.translateTextPart(lineWithoutEnglish);

                            // "할당, 결합된, 상급" 접두사 텍스트 복원 로직
                            if (assignmentText) {
                                if (assignmentText === "결합된") {
                                    translatedLine = "Synthesised " + translatedLine;
                                } else if (assignmentText === "상급") {
                                    translatedLine = "Superior " + translatedLine;
                                } else {
                                    let translatedAssignmentText = this.translateTextPart(assignmentText.trim()); // "할당" 텍스트 번역
                                    translatedLine = translatedAssignmentText + " " + translatedLine; // 번역된 문장 앞에 "할당" 추가
                                }
                            }

                            // 번역된 문장에 숫자 데이터 복원
                            numbers.forEach((number) => {
                                translatedLine = translatedLine.replace('#', number); // 숫자 복원
                            });

                            // 번역된 텍스트와 플레이스홀더 재조합
                            let finalTranslatedLine = translatedLine; // 이미 번역된 텍스트

                            // placeholders 배열의 각 항목에 대해 반복
                            placeholders.forEach(item => {
                                // 번역된 텍스트 끝에 플레이스홀더의 텍스트를 괄호와 함께 추가
                                finalTranslatedLine += ` (${item.text})`;
                            });

                            // 최종 결과 출력 또는 사용
                            console.log("최종 번역된 텍스트:", finalTranslatedLine);

                            return finalTranslatedLine;
                        }
                    }
                });
                console.log("최종 번역된 텍스트:", translatedLines);
                this.outputText = translatedLines.join('\n'); // 번역된 줄들을 다시 합쳐 출력 텍스트로 설정합니다.
                return true
            } catch (err) {
                console.error('번역 중 실패:', err);
            }
        },
        translateTextPart(text) {
            let translatedText = text;
            // Object.prototype.hasOwnProperty를 사용하여 안전하게 속성 확인
            if (Object.prototype.hasOwnProperty.call(this.translations, translatedText)) {
                // 주어진 텍스트가 this.translations 객체의 키라면 해당 값을 반환
                return this.translations[text];
            } else {
                // 텍스트가 객체의 키가 아니라면 원본 텍스트를 그대로 반환
                return text;
            }
        },
        translateItemClass(text) {
            let translatedText = text;
            // Object.prototype.hasOwnProperty를 사용하여 안전하게 속성 확인
            if (Object.prototype.hasOwnProperty.call(this.itemClass, translatedText)) {
                // 주어진 텍스트가 this.translations 객체의 키라면 해당 값을 반환
                return this.itemClass[text];
            } else {
                // 텍스트가 객체의 키가 아니라면 원본 텍스트를 그대로 반환
                return text;
            }
        },
        async copyToClipboard() {
            try {
                // "outputTextArea" 아이디를 가진 textarea 요소를 찾습니다.
                const textarea = document.getElementById('outputTextArea');
                if (!textarea) {
                    alert('복사할 텍스트가 포함된 요소를 찾을 수 없습니다.');
                    return;
                }
                // textarea의 텍스트를 가져옵니다.
                const textToCopy = textarea.value;
                if (textToCopy === '') {
                    alert('복사할 텍스트가 없습니다.');
                    return;
                }
                // 폴리필 라이브러리를 사용하여 클립보드에 텍스트를 복사합니다.
                // 여기서는 navigator.clipboard API를 직접 사용하는 것처럼 보이지만,
                // 폴리필이 적용되어 있으면 브라우저가 지원하지 않는 경우에도 작동합니다.
                navigator.clipboard.writeText(textToCopy).then(() => {
                    alert('클립보드에 복사되었습니다.');
                }, (err) => {
                    console.error('복사 실패:', err);
                    alert('복사에 실패했습니다. 콘솔을 확인해주세요.');
                });
                return true

            } catch (err) {
                console.error('클립 보드로 복사 중 실패:', err);
                alert('복사에 실패했습니다. 권한 문제를 확인해주세요.');

            }
        },
        async executeTranslationFlow() {
            const languageCode = await this.readyTranslateText();
            console.log("languageCode 값은?" + languageCode)
            if (languageCode === "ko") {
                const translated = await this.translateKoreanText();
                if (translated) {
                    await this.copyToClipboard();
                }
            } else if (languageCode === "en") {
                alert("영어 > 한국어는 구현 중입니다.")
            }
        }

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
