<template>
  <div id="app">
    <div class="text-end">
      <a target="_blank" href="https://www.youtube.com/@minugi"><img alt="Discord logo" src="./assets/images/yt_icon_rgb.png" style="height: 30px; margin-right: 20px"></a>
      <a target="_blank" href="https://discord.gg/kEm2G2qcZQ"><img alt="Youtube logo" src="./assets/images/icon_clyde_white_RGB.png" style="height: 30px; margin-right: 60px"></a>
    </div>
    <!-- <TranslateText/> -->
    <!-- <nav>
      <router-link to="/">POE 한국어 아이템 영어 변환기</router-link>
      <router-link to="/poe/item-translate">소개</router-link>
    </nav> -->
    <router-view />
  </div>
</template>

<script>
// import TranslateText from './components/TranslateText.vue'

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
</style>
