import { createRouter, createWebHistory } from 'vue-router';
import TranslateText from '@/components/TranslateText.vue';

const routes = [
    {
        path: '/',
        name: 'Main',
        component: TranslateText,
        meta: {
            title: '서미누기',
            metaTags: [
                // {
                //     name: 'description',
                //     content: '서미누기'
                // },
                // {
                //     property: 'og:title',
                //     content: '서미누기',
                // },
                // {
                //     property: 'og:description',
                //     content: '서미누기 닷컴'
                // }
                {
                    name: 'description',
                    content: '서미누기의 POE 한국어 아이템 정보 영어 번역기입니다. 한국어 아이템 정보를 영어로 번역해보세요.'
                },
                {
                    property: 'og:title',
                    content: '서미누기 - POE 한국어 아이템 정보 영어 번역기',
                },
                {
                    property: 'og:description',
                    content: '서미누기의 POE 한국어 아이템 정보 영어 번역기입니다. 한국어 아이템 정보를 영어로 번역해보세요.'
                }
            ]
        }
    },
    {
    path: '/poe/item-translate',
    name: 'TranslateText',
    component: TranslateText,
    meta: {
        title: '서미누기 - POE 한국어 아이템 정보 영어 번역기',
        metaTags: [
            {
                name: 'description',
                content: '서미누기의 POE 한국어 아이템 정보 영어 번역기입니다. 한국어 아이템 정보를 영어로 번역해보세요.'
            },
            {
                property: 'og:title',
                content: '서미누기 - POE 한국어 아이템 정보 영어 번역기',
            },
            {
                property: 'og:description',
                content: '서미누기의 POE 한국어 아이템 정보 영어 번역기입니다. 한국어 아이템 정보를 영어로 번역해보세요.'
            }
        ]
    }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// 라우트 변경 시 타이틀 업데이트
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '서미누기';
    next();
});

export default router;
