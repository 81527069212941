<template>
    <div class="col-md-12">
        <p>↓아래의 쿠팡 링크로 쿠팡에서 구매 시 금액에 비례한 쿠팡 파트너스 수익이 <span class="text-info fw-bolder">서미누기</span>에게 지급됩니다!</p>
        <div ref="coupangAdContainer"></div>
    </div>
</template>

<script>
export default {
    name: 'CoupangPartners',
    mounted() {
        this.loadCoupangAd();
    },
    methods: {
        loadCoupangAd() {
            // 첫 번째 스크립트 태그를 동적으로 생성하고 추가
            const script1 = document.createElement('script');
            script1.src = 'https://ads-partners.coupang.com/g.js';
            script1.async = true;
            script1.onload = () => {
                console.log('Coupang script loaded');

                // 두 번째 스크립트를 동적으로 생성하고 실행
                const script2 = document.createElement('script');
                script2.innerHTML = `
                new PartnersCoupang.G({
                "id": 791711,
                "template": "carousel",
                "trackingCode": "AF3678517",
                "width": "100%",
                "height": "200",
                "tsource": ""
                });
            `;
                this.$refs.coupangAdContainer.appendChild(script2);
            };
            this.$refs.coupangAdContainer.appendChild(script1);
        }
    }
};
</script>

<style scoped>
/* 필요한 경우 추가 스타일 */
</style>