/* eslint-disable no-undef */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Google Analytics 스크립트를 로드합니다.
function loadGAScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-GXRCFE8KPM';
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', 'G-GXRCFE8KPM');
    };
}

const app = createApp(App);

app.use(router);

router.afterEach((to) => {
    if (typeof gtag === 'function') {
        gtag('config', 'G-GXRCFE8KPM', {
            page_path: to.fullPath,
        });
    }
});

app.mount('#app');

// Google Analytics 스크립트를 로드합니다.
loadGAScript();
